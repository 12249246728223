import React, { useRef, useState, useEffect } from "react";
import "./contactsection2.css";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { FadeLoader } from "react-spinners";
import images from "../../constants/images";
import { BsChevronDown } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Contactsection2 = ({ scrollPosition }) => {
  const { t, i18n } = useTranslation();
  const [modalopen, setmodalopen] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Submit");
  const formRef = useRef(null);
  const formRef2 = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValuedrop, setSelectedValuedrop] = useState(null);
  const [getprofessional, setProfessional] = useState(null);
  // const [educationOptions, setEducationOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  // const [selectedValue, setSelectedValue] = useState("");

  const [validation, setValidation] = useState({
    from_name: "",
    last_name:"",
    email: "",
    message: "",
    education: "",
    professional_field: "",
    age: "",
  });

  const [inquiryType, setInquiryType] = useState(0);

  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const nameValidation = /^[A-Za-z\s\W]+$/;
  const [getname, setname] = useState("");
  const [getlname, setlname] = useState("");
  const [getemail, setEmail] = useState("");
  const [getmessage, setMessage] = useState("");
  const [getcon, setcon] = useState(false);

 // Update validation errors on language change
  useEffect(() => {
    const handleLanguageChange = () => {
      if (Object.keys(validation).length > 0) {
        validateForm(); 
      }
    };
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, validation]);

  // Update button text on language or inquiryType change
  useEffect(() => {
    handleBttons(
      inquiryType,
      inquiryType === 1 ? t("Send Your Application") : t("Send Your Question")
    );
  }, [i18n.language, inquiryType]); 

  // Event handler to update state when radio button is changed
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    console.log("Selected value:", event.target.value);
  };

  // Event handler to update state when select option is changed
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValuedrop(selectedOption);
    console.log("Selected value:", selectedOption); // Log selected value to console
  };
  const handleProfessionChange = (event) => {
    const selectedOption = event.target.value;
    setProfessional(selectedOption);
    console.log("Selected value:", selectedOption); // Log selected value to console
  };

  useEffect(() => {
    console.log("scrollPosition->", scrollPosition);
    console.log("inquiryType->", inquiryType);
    if (scrollPosition == 1) {
      handleBttons(1,t("Send Your Application"));
    } else {
      handleBttons(0,t("Send Your Question"));
    }
  }, [scrollPosition]);

  const handleSuccessModal = () => {
    setmodalopen(true);
    setTimeout(() => {
      setmodalopen(false);
    }, 3000);
  };

  useEffect(() => {
    getEducation();
    getProfession();
  }, []);

  const validateForm = () => {
    const newValidation = {};

    if (getname === "") {
      newValidation.from_name = t("please enter first name");
    } else if (!nameValidation.test(getname)) {
      newValidation.from_name = t("Name can only contain alphabetic characters");
    }

    if (getlname === "") {
      newValidation.last_name = t("please enter last name");
    } else if (!nameValidation.test(getlname)) {
      newValidation.last_name = t("Name can only contain alphabetic characters");
    }

    if (getemail === "") {
      newValidation.email = t("please enter email");
    } else if (!getcon) {
      newValidation.email = t("please enter a valid email");
    }

    if (selectedValuedrop == null || selectedValuedrop === "") {
      newValidation.education = t("please select Highest level of education");
    }

    if (getprofessional === "" || getprofessional == null) {
      newValidation.professional_field = t("please select Professional field");
    }

    if (selectedValue === "" || selectedValue == null) {
      newValidation.age = t("please select age");
    }
    if (getmessage === "") {
      newValidation.message = t("please enter message");
    }

    setValidation(newValidation);
    return Object.keys(newValidation).length === 0;
  };


  const sendForm1 = async () => {
    const newValidation = {};
    if (getname === "") {
      newValidation.from_name = t("please enter first name");
    } else if (!nameValidation.test(getname)) {
      newValidation.from_name = t("Name can only contain alphabetic characters");
    }
    if (getlname === "") {
      newValidation.last_name = t("please enter last name");
    } else if (!nameValidation.test(getlname)) {
      newValidation.last_name = t("Name can only contain alphabetic characters");
    }
    if (getemail === "") {
      newValidation.email = t("please enter email");
    } else if (!getcon) {
      newValidation.email = t("please enter a valid email");
    }
    if (selectedValuedrop == null || selectedValuedrop == "") {
      newValidation.education = t("please select Highest level of education");
    }
    if (getprofessional === "" || getprofessional === null ) {
      newValidation.professional_field = t("please select Professional field");
    }
    if (selectedValue == "" || selectedValue == null) {
      newValidation.age = t("please select age");
    }
    
    setValidation(newValidation);

    if (Object.keys(newValidation).length === 0) {
      setLoading(true);
      const param = {
        name: getname,
        last_name:getlname,
        email: getemail,
        inquiry_for: inquiryType,
        education_id: selectedValuedrop,
        profession_id: getprofessional,
        age: selectedValue,
      };

      console.log("param", param);

      await axios
        .post("https://gokulnair.com/usaintern/api/add_inquiry", param, {})
        .then((res) => {
          if (res.data.status == 1) {
            setLoading(false);
            handleSuccessModal();
            setname("");
            setlname("");
            setEmail("");
            setMessage("");
            setInquiryType(0);
            setSelectedValuedrop(null);
            setProfessional(null);
            setSelectedValue(null);
            console.log("Form 1 submission complete");
            // Push the event to the GTM data layer
            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'formSubmission',
                FormData: param, // optional: pass form data
              });
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  };



  const sendForm2 = async () => {
    const newValidation = {};
    if (getname === "") {
      newValidation.from_name = t("please enter first name");
    } else if (!nameValidation.test(getname)) {
      newValidation.from_name = t("Name can only contain alphabetic characters");
    }
    if (getlname === "") {
      newValidation.last_name = t("please enter last name");
    } else if (!nameValidation.test(getlname)) {
      newValidation.last_name = t("Name can only contain alphabetic characters");
    }
    if (getemail === "") {
      newValidation.email = t("please enter email");
    } else if (!getcon) {
      newValidation.email = t("please enter a valid email");
    }
    if (getprofessional === "" || getprofessional === null ) {
      newValidation.professional_field = t("please select Professional field");
    }
    if (getmessage === "") {
      newValidation.message = t("please enter message");
    }

    setValidation(newValidation);

    if (Object.keys(newValidation).length === 0) {
      setLoading(true);
      const param2 = {
        name: getname,
        last_name:getlname,
        email: getemail,
        message: getmessage,
        profession_id: getprofessional,
        inquiry_for: inquiryType,
      };
      console.log("param2", param2);
      await axios
        .post("https://gokulnair.com/usaintern/api/add_inquiry", param2, {})
        .then((res) => {
          setLoading(false);
          if (res.data.status == 1) {
            handleSuccessModal();
            setname("");
            setlname("");
            setEmail("");
            setInquiryType(0);
            setSelectedValuedrop(null);
            setProfessional(null);
            setMessage("");
            setSelectedValue(null);
            console.log("Form 2 submission complete");
            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'formSubmission',
                FormData: param2, // optional: pass form data
              });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
      console.log("param2", param2);
    }
  };


  const getEducation = () => {
    axios
      .get("https://gokulnair.com/usaintern/api/get_education")

      .then((Response) => {
        setLoading(false);
        if (Response.data.status === 1) {
          setEducationOptions(Response.data.eduction_data);
        } else {
          console.log("Error:", Response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error:", err);
      });
  };
  const getProfession = () => {
    axios
      .get("https://gokulnair.com/usaintern/api/get_profession")

      .then((Response) => {
        setLoading(false);
        if (Response.data.status === 1) {
          setProfessionOptions(Response.data.profession_data);
        } else {
          console.log("Error:", Response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error:", err);
      });
  };

  const validateField = (value, fieldName) => {
    if (!value) {
      return `${fieldName} is required`;
    }
    if (/\d/.test(value)) {
      return "Cannot enter numbers";
    }
    return "";
  };

  const validateMsg = (value, fieldName) => {
    if (!value) {
      return `${fieldName} is required`;
    }
    return "";
  };

  const validateEmail = (email) => {
    if (!email) {
      return "Email Address is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Invalid email address";
    }
    return "";
  };

  const handleGeneralInquiryClick = () => {
    setInquiryType(0); // Set inquiryType to 0 for General Inquiry
    setSubmitButtonText("Send Your Question");
  };

  const handleApplyNowClick = () => {
    setInquiryType(1); // Set inquiryType to 1 for Apply Now
    setSubmitButtonText("Send Your Application");
  };

  let handleBttons = (id, name) => {
    setInquiryType(id);
    setSubmitButtonText(name);
  };

  return (
    <div>
      <div className="contact-section3">
        <div className="contact-sec3-wrap">
          <div className="contact-sec3-form-div">
            <div className="contact-form">
              <h2>{t("Get More Information")}</h2>
              <div className="form-group contact_div">
                <button
                  type="button"
                  className={
                    inquiryType === 1
                      ? "apply_btn_con clicked"
                      : "apply_btn_con"
                  }
                  // onClick={handleApplyNowClick}

                  onClick={() => {
                    handleBttons(1, t("Send Your Application"));
                  }}
                >
                  {t("apply now")}
                </button>
                <button
                  type="button"
                  className={
                    inquiryType === 0
                      ? "apply_btn_con clicked"
                      : "apply_btn_con"
                  }
                  onClick={() => {
                    handleBttons(0, t("Send Your Question"));
                  }}
                >
                  {t("General Inquiry")}
                </button>
              </div>
              {inquiryType === 1 ? (
                <div>
                  <div className="form-group validation">
                    <label htmlFor="name">{t("First Name")}*</label>
                    <br />
                    <input
                      type="text"
                      name="from_name"
                      className="form-name"
                      placeholder={t("Your First Name")}
                      value={getname}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                    {validation.from_name && (
                      <div className="val_1">{validation.from_name}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="name">{t("Last Name")}*</label>
                    <br />
                    <input
                      type="text"
                      name="last_name"
                      className="form-name"
                      placeholder={t("Your Last Name")}
                      value={getlname}
                      onChange={(e) => {
                        setlname(e.target.value);
                      }}
                    />
                    {validation.last_name && (
                      <div className="val_1">{validation.last_name}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="email">{t("Email")}*</label>
                    <br /> 
                    <input
                      type="email"
                      name="email"
                      className="form-name"
                      placeholder={t("Your Email")}
                      value={getemail}
                      onChange={(e) => {
                        const text = e.target.value;
                        if (email_validation.test(text) === false) {
                          setcon(false);
                        } else if (text.length == 0) {
                          setcon(false);
                        } else {
                          setcon(true);
                        }
                        setEmail(text);
                      }}
                    />
                    {validation.email && (
                      <div className="val_1">{validation.email}</div>
                    )}
                  </div>
                  <div className="form-group validation" >
                    <label htmlFor="Education">
                      {t("Highest level of education")}*
                    </label>
                    <br />
                    <div style={{ position: "relative" }}>
                      <select
                        className="form-select"
                        onChange={handleSelectChange} // Update state and log value on change
                      >
                        <option value="">{t("Select Education")}</option>
                        {educationOptions.map((option) => (
                          <option
                            key={option.education_id}
                            value={option.education_id}
                          >
                            {t(option.education_name)}
                          </option>
                        ))}
                      </select>
                      <BsChevronDown className="select_arrow" />
                    </div>
                    {validation.education && (
                      <div className="val_1">{validation.education}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="Professional_field">
                      {t("Professional field")}*
                    </label>
                    <br />

                    <div style={{ position: "relative" }}>
                    <select
                      className="form-select"
                      onChange={handleProfessionChange} // Update state and log value on change
                    >
                      <option value="">{t("Select Professional field")}</option>
                        {professionOptions.map((option) => (
                          <option
                            key={option.profession_id}
                            value={option.profession_id}
                          >
                            {t(option.profession_name)}
                          </option>
                        ))}
                    </select>
                      <BsChevronDown className="select_arrow" />
                    </div>

              
                    {validation.professional_field && (
                      <div className="val_1">{validation.professional_field}</div>
                    )}
                  </div>
                  <div
                    className="form-group validationr"
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="Age">
                      {t("Are you between 18-32 years old?")}*
                    </label>
                    <br />
                    <div className="radio_btn">
                      <input
                        type="radio"
                        value={1}
                        name="age"
                        checked={selectedValue == 1} // Check if selectedValue is "Yes"
                        onChange={handleRadioChange}
                      />{" "}
                      {t("Yes")}
                      <input
                        type="radio"
                        value={0}
                        name="age"
                        checked={selectedValue == 0} // Check if selectedValue is "No"
                        onChange={handleRadioChange}
                      />{" "}
                      {t("No")}
                    </div>
                    {validation.age && (
                      <div className="val_1">{validation.age}</div>
                    )}
                    {selectedValue == 1 && (
                      <div className="note-text">
                        
                      </div>
                    )}
                    {selectedValue == 0 && (
                      <div className="note-text">
                        {t("Note: Our program is designed for individuals between 18 and 32 years old. However, we still encourage you to apply, and we will review your application on a case-by-case basis.")}
                      </div>
                    )}
                  </div>
                  <button onClick={sendForm1}>{submitButtonText}</button>
                </div>
              ) : (
                ""
              )}
              {inquiryType === 0 ? (
                <div>
                  <div className="form-group validation">
                    <label htmlFor="name">{t("First Name")}*</label>
                    <br />
                    <input
                      type="text"
                      name="from_name"
                      className="form-name"
                      placeholder={t("Your First Name")}
                      value={getname}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                    {validation.from_name && (
                      <div className="val_1">{validation.from_name}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="name">{t("Last Name")}*</label>
                    <br />
                    <input
                      type="text"
                      name="last_name"
                      className="form-name"
                      placeholder={t("Your Last Name")}
                      value={getlname}
                      onChange={(e) => {
                        setlname(e.target.value);
                      }}
                    />
                    {validation.last_name && (
                      <div className="val_1">{validation.last_name}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="email">{t("Email")}*</label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      className="form-name"
                      placeholder={t("Your Email")}
                      value={getemail}
                      onChange={(e) => {
                        const text = e.target.value;
                        if (email_validation.test(text) === false) {
                          setcon(false);
                        } else if (text.length == 0) {
                          setcon(false);
                        } else {
                          setcon(true);
                        }
                        setEmail(text);
                      }}
                    />
                    {validation.email && (
                      <div className="val_1">{validation.email}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="Professional_field">
                      {t("Professional field")}*
                    </label>
                    <br />

                    <div style={{ position: "relative" }}>
                    <select
                      className="form-select"
                      onChange={handleProfessionChange} // Update state and log value on change
                    >
                      <option value="">{t("Select Professional field")}</option>
                        {professionOptions.map((option) => (
                          <option
                            key={option.profession_id}
                            value={option.profession_id}
                          >
                            {t(option.profession_name)}
                          </option>
                        ))}
                    </select>
                      <BsChevronDown className="select_arrow" />
                    </div>

              
                    {validation.professional_field && (
                      <div className="val_1">{validation.professional_field}</div>
                    )}
                  </div>
                  <div className="form-group validation">
                    <label htmlFor="message">{t("Message")}*</label>
                    <br />
                    <textarea
                      name="message"
                      rows="4"
                      className="form-textarea"
                      value={getmessage}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                    {validation.message && (
                      <div className="val_1">{validation.message}</div>
                    )}
                  </div>
                  <button type="submit" onClick={sendForm2}>
                    {submitButtonText}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="contact-sec3-content-div">
            <p className="con-sec3-title">{t("Follow Us On Social Media")}</p>
            <br />
            <p className="con-sec3-des">
              {t("Stay connected with the latest updates, success stories, and opportunities by following our social media channels and be a part of the vibrant USA Intern Club community.")}
            </p>
            <div className="con-social-btn-div">
              <button>
                <a
                  href="https://www.instagram.com/usaintern.club/"
                  target="_blank"
                >
                  <img
                    src={images.insta}
                    alt="instagram"
                    className="social-img-1"
                  />
                  <img
                    src={images.insta_hover}
                    alt="instagram"
                    className="social-img-2"
                  />
                </a>
              </button>
              <button>
                <a
                  href="https://www.facebook.com/usaintern.club"
                  target="_blank"
                >
                  <img src={images.fb} alt="fb" className="social-img-1" />
                  <img
                    src={images.fb_hover}
                    alt="fb"
                    className="social-img-2"
                  />
                </a>
              </button>
              <button>
                <a
                  href="https://www.tiktok.com/@usaintern.club?_t=8jeZfuyhRVK&_r=1"
                  target="_blank"
                >
                  <img
                    src={images.tiktok}
                    alt="tiktok"
                    className="social-img-1"
                  />
                  <img
                    src={images.tiktok_hover}
                    alt="tiktok"
                    className="social-img-2"
                  />
                </a>
              </button>
              <button>
                <a
                  href="https://www.linkedin.com/company/usaintern-club"
                  target="_blank"
                >
                  <img
                    src={images.linkedin}
                    alt="linkedin"
                    className="social-img-1"
                  />
                  <img
                    src={images.linkedin_hover}
                    alt="linkedin"
                    className="social-img-2"
                  />
                </a>
              </button>
              {/* <button>
                                <a href="skype:usaintern.club?chat" target="_blank">
                                    <img src={images.skype_1} alt="skype_1" className='social-img-1' />
                                    <img src={images.skype_hover} alt="skype_1" className='social-img-2' />
                                </a>
                            </button>     */}
            </div>
          </div>
        </div>
        {modalopen === true ? (
          <div className="modal_screen">
            <div className="" style={{ textAlign: "end" }}>
              <AiOutlineClose
                className="modal_close"
                onClick={() => setmodalopen(false)}
              />
            </div>
            <img className="body_img" src={images.check} alt="" />
            <h1 className="body_h1">Success</h1>
          </div>
        ) : (
          ""
        )}
      </div>
      {loading && (
        <div className="loading_screen">
          <div className="loader_contact"></div>
          {/* <img src={images.Spinnerr} alt="Spinner" /> */}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Contactsection2;
